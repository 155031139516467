import { MetafieldTypes, type UnlikelyMetafield, type UnlikelyMetafields } from "@unlikelystudio/commerce-connector"

import type { Nullish, ObjectValues } from "~/@types/generics"
import { createMetafieldInput } from "~/lib/shopify/metafields/utils/create-metafield-input"

/***** MISC */

export type MetafieldInput = UnlikelyMetafield & {
  processedKey: string
}

type MetafieldInputs = Record<string, (value: string) => Omit<MetafieldInput, "ownerId">>

export const METAFIELD_DEFAULT_VALUE = "unspecified"

/***** PRODUCT */

type ProductMetafield = typeof PRODUCT_METAFIELDS

export const PRODUCT_METAFIELDS = {
  TAG: "edito.tag",
  SUBTITLE: "edito.subtitle",
  EDITO_COLLECTION_SLICE: "edito.collection_slice",
  COMPOSITION_ENTRETIEN: "accordion.composition_entretien",
  CONFECTION_SAVOIR_FAIRE: "accordion.confection_savoir_faire",
  LIVRAISON_RETOURS: "accordion.livraison_retours",
  IS_BUNDLE: "bundle.is_bundle",
  BUNDLE_HIDE_FROM_PRICE_LABEL: "bundle.hide_from_price_label",
  SIZE_CHART: "panel.size_chart",
  EMBROIDERY: "panel.embroidery",
  EMBROIDERY_TITLE: "panel.embroidery_title",
  EMBROIDERY_DESCRIPTION: "panel.embroidery_description",
  EMBROIDERY_IMAGES: "panel.embroidery_images",
  EMBROIDERY_EMBROIDERY_TITLE: "panel.embroidery_embroidery_title",
  EMBROIDERY_EMPLACEMENT: "panel.embroidery_emplacement",
  HIDE_VARIANTS_IN_BUNDLES: "product.hide_variants_in_bundles",
  HIDE_VARIANTS_IN_SIMPLE_PRODUCTS: "product.hide_variants_in_simple_products",
  CROSS_SELL_PRODUCTS: "cross_sell.products",
  CROSS_SELL_TITLE: "cross_sell.title",
  CUSTOM_DEFAULT_IMG: "my_fields.custom_default_img",
  CUSTOM_DEFAULT_IMG_HOVER: "my_fields.custom_default_img_hover",
  PRODUCT_NAME_IN_BUNDLE: "product.product_name_in_bundle",
  CUSTOM_BUNDLES_MIXED_COLORS: "product.custom_bundles_mixed_colors",
  SELECTED_COLOR: "product.selected_color_variant",
  PRODUCT_REASSURANCE: "reassurance.product_reassurance",
  BUNDLE_DISABLE_EMBROIDERY: "product.bundle_disable_embroidery",
  IS_SEARCHABLE: "product.is_searchable",
  BREADCRUMB_COLLECTIONS_ORDER: "breadcrumb.collections_order",
  ASSOCIATED_PRODUCTS: "global.associated_products",
} as const

export function getProductMetafieldProccessedKeys(...keys: (keyof ProductMetafield)[]) {
  return keys.map((key) => PRODUCT_METAFIELDS[key])
}

export function getProductMetafieldValue(metafields: Nullish<UnlikelyMetafields>, key: keyof ProductMetafield) {
  return metafields ? metafields[PRODUCT_METAFIELDS[key]]?.value ?? null : null
}

/***** COLLECTION */

type CollectionMetafield = typeof COLLECTION_METAFIELDS

export const COLLECTION_METAFIELDS = {
  MATERIALS_PANEL: "panel.materials",
  REMOVE_FROM_BREADCRUMB: "custom.remove_from_breadcrumb",
} as const

export function getCollectionMetafieldProccessedKeys(...keys: (keyof CollectionMetafield)[]) {
  return keys.map((key) => COLLECTION_METAFIELDS[key])
}

export function getCollectionMetafieldValue(metafields: Nullish<UnlikelyMetafields>, key: keyof CollectionMetafield) {
  return metafields ? metafields[COLLECTION_METAFIELDS[key]]?.value ?? null : null
}
/***** VARIANT */

type VariantMetafield = typeof VARIANT_METAFIELDS

export const VARIANT_METAFIELDS = {
  BUNDLE_APP: "bundles_app.content",
  COLOR_DOT_IMAGE: "variant.color_dot_image",
  HIDDEN_ON_MARKET: "variant.hidden_on_markets",
  COLOR_TAG: "variant.color_tag",
} as const

export function getVariantMetafieldProccessedKeys(...keys: (keyof VariantMetafield)[]) {
  return keys.map((key) => VARIANT_METAFIELDS[key])
}

export function getVariantMetafieldValue(metafields: Nullish<UnlikelyMetafields>, key: keyof VariantMetafield) {
  return metafields ? metafields[VARIANT_METAFIELDS[key]]?.value : null
}

/***** CUSTOMER */
export const CUSTOMER_METAFIELDS = {
  GENDER: "customer.gender",
  BIRTHDAY: "customer.birthday",
} as const
export type CustomerMetafieldsKeys = ObjectValues<typeof CUSTOMER_METAFIELDS>

export const CUSTOMER_METAFIELD_INPUTS = {
  BIRTHDAY: (value: string) =>
    createMetafieldInput<CustomerMetafieldsKeys>(
      CUSTOMER_METAFIELDS.BIRTHDAY,
      MetafieldTypes.single_line_text_field,
      value
    ),
  GENDER: (value: string) =>
    createMetafieldInput<CustomerMetafieldsKeys>(
      CUSTOMER_METAFIELDS.GENDER,
      MetafieldTypes.single_line_text_field,
      value
    ),
} satisfies MetafieldInputs

export function getCustomerMetafieldValue(
  metafields: Nullish<UnlikelyMetafields>,
  key: keyof typeof CUSTOMER_METAFIELDS
) {
  return metafields ? metafields[CUSTOMER_METAFIELDS[key]]?.value : null
}
